import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import styled from "styled-components";
import AddButton from "./AddButton";

const CardRecommend = () => {
  const [quantity, setQuantity] = useState(0);

  return (
    <Card
      sx={{
        padding: "10px",
        cursor: "pointer",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <CardMedia
        component="img"
        height="170"
        sx={{ borderRadius: "10px" }}
        image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
        alt="green iguana"
      />
      <CardContent
        sx={{
          padding: {
            xs: "0px !important",
            md: "16px 16px 0px 16px !important",
          },
          minHeight: "110px",
        }}
      >
        <Typography gutterBottom variant="h5" component="div">
          Lizard
        </Typography>
        <Typography variant="body2" color="text.secondary">
          30.000d
        </Typography>
        <Typography
          sx={{ display: true ? "block" : "none", marginTop: "10px" }}
        >
          <AddButton quantity={quantity} setQuantity={setQuantity} />
        </Typography>
      </CardContent>
    </Card>
  );
};

const CardMenu = () => {
  const [quantity, setQuantity] = useState(0);
  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "10px",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "65%",
          paddingRight: "10px",
        }}
      >
        <CardContent sx={{ flex: "1 0 auto", padding: "10px 0px !important" }}>
          <Typography component="div" variant="h5">
            Lizard
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            description
          </Typography>
        </CardContent>
        <Box sx={{}}>10.000d</Box>
        <AddButton quantity={quantity} setQuantity={setQuantity} />
      </Box>
      <CardMedia
        component="img"
        sx={{ width: "35%", borderRadius: "10px" }}
        image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
        alt="Live from space album cover"
      />
    </Card>
  );
};

const Title = styled("h2")(() => ({}));

const Menu = () => {
  return (
    <>
      <Box>
        <Title>Recommended</Title>
        <Grid container columnSpacing={{ lg: 3, xs: 1 }} rowSpacing={2}>
          <Grid item md={6} xs={6} lg={3}>
            <CardRecommend />
          </Grid>
          <Grid item md={6} xs={6} lg={3}>
            <CardRecommend />
          </Grid>
          <Grid item md={6} xs={6} lg={3}>
            <CardRecommend />
          </Grid>
          <Grid item md={6} xs={6} lg={3}>
            <CardRecommend />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginTop: "50px" }}>
        <Title>Makanan Utama</Title>
        <Grid container spacing={{ lg: 2, md: 2, sx: 2 }} rowSpacing={2}>
          <Grid item lg={4} md={12} sx={12}>
            <CardMenu />
          </Grid>
          <Grid item lg={4} md={12} sx={12}>
            <CardMenu />
          </Grid>
          <Grid item lg={4} md={12} sx={12}>
            <CardMenu />
          </Grid>
          <Grid item lg={4} md={12} sx={12}>
            <CardMenu />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Menu;
