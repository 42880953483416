import { Button, Divider } from "@mui/material";
import { Box } from "@mui/system";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import styled from "styled-components";
import StarComponent from "./header/Star";
import DistanceComponent from "./header/Distance";
import DistancePriceComponent from "./header/DistancePrice";
import TopRating1 from "./header/TopRating1";

const SUCCESS_COLOR = "#00880d";
const SUCCESS_LIGHT_COLOR = "rgba(0, 136, 13, .1)";

export const BoxCustom = styled(Box)(() => ({
  marginTop: "25px",
}));

const Header = () => {
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <BoxCustom sx={{ marginTop: 0 }}>
            <h3>Warung Oma, Senen</h3>
          </BoxCustom>
          <BoxCustom>
            <Button
              sx={{ borderRadius: 10, backgroundColor: "#c85200" }}
              variant="contained"
              startIcon={<ThumbUpIcon />}
            >
              Supper partner
            </Button>
            <span style={{ marginLeft: "5px" }}>Rice</span>
          </BoxCustom>
          <BoxCustom>
            <Button
              sx={{
                borderRadius: 10,
                marginRight: "20px",
                backgroundColor: SUCCESS_LIGHT_COLOR,
                border: "1px solid rgba(0, 136, 13, .1)",
                color: "black",
              }}
              variant="outlined"
              startIcon={
                <FiberManualRecordIcon sx={{ color: SUCCESS_COLOR }} />
              }
            >
              Open
            </Button>

            <span style={{ color: SUCCESS_COLOR, paddingRight: "20px" }}>
              Opening hours
            </span>
            <span>Nguyen Khang, HN, Viet Nam</span>
          </BoxCustom>
          <BoxCustom sx={{ display: "flex" }}>
            <StarComponent />
            <Divider orientation="vertical" flexItem sx={{ color: "grey" }} />
            <DistanceComponent />
            <Divider orientation="vertical" flexItem sx={{ color: "grey" }} />
            <DistancePriceComponent />
            <Divider
              orientation="vertical"
              flexItem
              sx={{ display: { xs: "none", md: "block", color: "grey" } }}
            />
            <TopRating1 />
            <Divider
              orientation="vertical"
              flexItem
              sx={{ display: { xs: "none", md: "block", color: "grey" } }}
            />
            <TopRating1 />
            <Divider
              orientation="vertical"
              flexItem
              sx={{ display: { xs: "none", md: "block", color: "grey" } }}
            />
            <TopRating1 />
          </BoxCustom>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <div></div>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
