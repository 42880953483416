import MenuIcon from "@mui/icons-material/Menu";
import { Divider, IconButton, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";

const SidebarContent = ({ isHidden, onCloseSidebar, setMobileCurrentView }) => {
  const onChange = (view) => {
    setMobileCurrentView(view);
    onCloseSidebar();
  };
  return (
    // <ClickAwayListener onClickAway={() => (isHidden ? null : onCloseSidebar())}>
    <Box
      sx={{
        width: isHidden ? "0px" : "220px",
        height: "100%",
        border: "1px solid #d3d3d3",
        opacity: isHidden ? "0" : "1",
        background: "white",
        zIndex: 10,
        position: isHidden ? "unset" : "absolute",
        transition: "width 0.5s",
        minHeight: "100vh",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            color="secondary"
            aria-label=""
            component="label"
            onClick={onCloseSidebar}
          >
            <ClearIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ padding: "10px" }}>
          <Button variant="text" onClick={() => onChange("DONATE")}>
            Donate
          </Button>
          <Divider sx={{ marginTop: "5px" }} />
        </Box>
        <Box sx={{ padding: "10px" }}>
          <Button variant="text" onClick={() => onChange("TOP_DONATE")}>
            Top donate
          </Button>
          <Divider sx={{ marginTop: "5px" }} />
        </Box>
      </Box>
    </Box>
    // </ClickAwayListener>
  );
};
const MobileSidebar = ({ mobileCurrentView, setMobileCurrentView }) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const openSidebar = () => {
    setIsOpenDrawer(!isOpenDrawer);
  };

  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        display: { xs: "block", md: "none" },
      }}
    >
      <MenuIcon
        onClick={openSidebar}
        sx={{ position: "absolute", top: 10, left: 10 }}
      />
      <SidebarContent
        isHidden={!isOpenDrawer}
        onCloseSidebar={() => setIsOpenDrawer(false)}
        setMobileCurrentView={setMobileCurrentView}
      />
    </Box>
  );
};

export default MobileSidebar;
