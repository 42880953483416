import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const getDonateInfo = async (slug) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const result = await axiosInstance.get("/api/donate/" + slug, { headers });
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const postPaymentInfo = async (message) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    return await axiosInstance.post("/api/payments/transaction", message, {
      headers,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const postPaymentNinePay = async (message) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    return await axiosInstance.post("/api/payments/9pay", message, {
      headers,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const postEstimate = async (amount) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    return await axiosInstance.post("api/estimate", amount, {
      headers,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getListPayment = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    return await axiosInstance.get("api/method", {
      headers,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
};
