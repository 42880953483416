import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "1px solid #d3d3d3",
  height: '70%',
  overflowY: 'scroll',
  borderRadius: '10px',
  // boxShadow: 24,
  p: 4,
};
const PolicyModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          <a href="#">TỔNG QUÁT</a> Các trang web này ("gwu.vn" và
          "monmisstudio.com"), Creator Sites, Monmi Studio Desktop, được vận
          hành bởi EPIS Cloud Inc thông qua EPIS VIETNAM. Trong toàn bộ trang
          web, các thuật ngữ “chúng tôi”, “chúng tôi” và “của chúng tôi” đề cập
          đến GWU. GWU cung cấp trang web này, bao gồm tất cả thông tin, công cụ
          và dịch vụ có sẵn từ trang web này cho bạn, người dùng, với điều kiện
          bạn chấp nhận tất cả các điều khoản, điều kiện, chính sách và thông
          báo được nêu ở đây. <br /> <br /> Bằng cách truy cập trang web của
          chúng tôi và / hoặc sử dụng dịch vụ của chúng tôi, bạn tham gia vào
          “Dịch vụ” của chúng tôi và đồng ý bị ràng buộc bởi các điều khoản và
          điều kiện sau (“Điều khoản dịch vụ”, “Điều khoản”), bao gồm các điều
          khoản và điều kiện và chính sách bổ sung được tham chiếu ở đây và /
          hoặc có sẵn theo siêu liên kết. Các Điều khoản Dịch vụ này áp dụng cho
          tất cả người dùng của trang web, bao gồm nhưng không giới hạn người
          dùng là trình duyệt, nhà cung cấp, khách hàng, người bán hàng, người
          bán và / hoặc người đóng góp nội dung. <br /> <br /> Vui lòng đọc kỹ
          các Điều khoản Dịch vụ này trước khi truy cập hoặc sử dụng trang web
          của chúng tôi. Bằng cách truy cập hoặc sử dụng bất kỳ phần nào của
          trang web, bạn đồng ý bị ràng buộc bởi các Điều khoản Dịch vụ này.{" "}
          <br /> <br /> Nếu bạn không đồng ý với tất cả các điều khoản và điều
          kiện của thỏa thuận này, thì bạn không thể truy cập trang web hoặc sử
          dụng bất kỳ dịch vụ nào. Nếu các Điều khoản Dịch vụ này được coi là
          một đề nghị, thì việc chấp nhận được giới hạn rõ ràng đối với các Điều
          khoản Dịch vụ này. Bất kỳ tính năng hoặc công cụ mới nào được thêm vào
          dịch vụ cũng sẽ phải tuân theo Điều khoản dịch vụ. Bạn có thể xem lại
          phiên bản mới nhất của Điều khoản Dịch vụ bất kỳ lúc nào trên trang
          này. Chúng tôi có quyền cập nhật, thay đổi hoặc thay thế bất kỳ phần
          nào của Điều khoản Dịch vụ này bằng cách đăng các cập nhật và / hoặc
          thay đổi lên trang web của chúng tôi. Bạn có trách nhiệm kiểm tra
          trang này định kỳ để biết các thay đổi. Việc bạn tiếp tục sử dụng hoặc
          truy cập vào trang web sau khi đăng bất kỳ thay đổi nào đồng nghĩa với
          việc bạn chấp nhận những thay đổi đó. <br /> <br />{" "}
          <a href="#">GIỚI THIỆU GWU</a> là công cụ phát trực tiếp dành cho
          Người sáng tạo. Các dịch vụ của GWU bao gồm phần mềm phát trực tiếp
          trên máy tính để bàn, ứng dụng di động, Trang web của người sáng tạo,
          Hàng hóa, Cửa hàng ứng dụng dành cho nhà phát triển và cung cấp các
          dịch vụ để Người sáng tạo tùy chỉnh, kiếm tiền và tối ưu hóa luồng
          trực tiếp của họ. GWU cung cấp dịch vụ miễn phí cũng như các dịch vụ
          trả phí cao cấp cho cả người xem và Người sáng tạo. GWU là một dịch vụ
          cho phép người xem gửi và nhận tiền ("tiền boa") thông qua bộ xử lý
          thanh toán của bên thứ ba. Dịch vụ của chúng tôi cũng có nhiều công cụ
          và tiện ích con có thể sử dụng nhiều dữ liệu khác nhau từ các dịch vụ
          của chúng tôi và dữ liệu từ các bên thứ ba được ủy quyền.
          <br /> <br /> <a>ĐIỀU KHOẢN NGƯỜI DÙNG DỊCH VỤ GWU </a> không dành cho
          những người dưới 13 tuổi. Nếu bạn ở độ tuổi từ 13 đến 18 (hoặc từ 13
          đến tuổi thành niên hợp pháp trong khu vực tài phán cư trú của bạn),
          bạn chỉ có thể sử dụng Dịch vụ GWU theo sự giám sát của cha mẹ hoặc
          người giám hộ hợp pháp đồng ý bị ràng buộc bởi các Điều khoản dịch vụ
          này. Dịch vụ GWU cũng không khả dụng cho bất kỳ người dùng nào trước
          đây đã bị EPIS Cloud xóa khỏi Dịch vụ GWU. Cuối cùng, GWU Dịch vụ
          không được cung cấp cho bất kỳ người nào bị cấm nhận chúng theo luật
          của Hoa Kỳ hoặc luật hiện hành ở bất kỳ khu vực tài phán nào khác. bất
          kỳ luật nào trong phạm vi quyền hạn của bạn (bao gồm nhưng không giới
          hạn ở luật bản quyền). Bạn không được truyền bất kỳ sâu hoặc vi rút
          hoặc bất kỳ mã nào có tính chất phá hoại. Việc vi phạm hoặc vi phạm
          bất kỳ Điều khoản nào sẽ dẫn đến việc Dịch vụ của bạn bị chấm dứt ngay
          lập tức. <br /> <br />
          BẰNG CÁCH TẢI XUỐNG, CÀI ĐẶT HOẶC CÁCH KHÁC SỬ DỤNG GWU CÁC DỊCH VỤ,
          BẠN TUYÊN BỐ RẰNG BẠN CÓ ÍT NHẤT 13 TUỔI, RẰNG PHỤ HUYNH HOẶC NGƯỜI
          BẢO LÃNH PHÁP LÝ CỦA BẠN ĐỒNG Ý ĐƯỢC BẰNG CÁC ĐIỀU KHOẢN DỊCH VỤ NÀY
          NẾU BẠN TRỞ LÊN GIỮA 13 VÀ ĐỘ TUỔI CHỦ YẾU HỢP PHÁP TRONG KHU CĂN CỨ
          CỦA BẠN VÀ BẠN CÓ KHÔNG BỊ LOẠI BỎ HOẶC CẤM TRƯỚC KHI THỰC HIỆN TRÊN
          GWU .
        </Typography>
      </Box>
    </Modal>
  );
};

export default PolicyModal;
