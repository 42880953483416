import { Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { getListPayment } from "../../api/axios";
import { PRIMARY_COLOR } from "../../helper/const";
import { Title } from "./Donate";

const SelectPayment = ({ setPayment, payment }) => {
  const [paymentList, setPaymentList] = useState(null);

  useEffect(() => {
    getPaymentList();
  }, []);

  const getPaymentList = async () => {
    const result = await getListPayment();
    setPaymentList(result.data.data);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: "20px",
        borderRadius: "6px",
        marginBottom: "10px",
      }}
    >
      <Title>Hình thức thanh toán</Title>
      <Grid container spacing={{ sm: 2, lg: 6, md: 4 }} rowSpacing={2}>
        {paymentList &&
          Object.keys(paymentList).map((keyName) => {
            return (
              <Grid
                key={keyName}
                item
                xs={12}
                lg={3}
                md={6}
                onClick={() => setPayment(keyName)}
              >
                <Paper
                  elevation={3}
                  sx={{
                    borderRadius: "10px",
                    height: 70,
                    cursor: "pointer",
                    boxShadow: " 2px 5px 17px 0 rgb(31 45 61 / 17%)",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    padding: "20px",
                    backgroundColor:
                      keyName === payment ? PRIMARY_COLOR : "white",
                  }}
                >
                  <h4
                    style={{
                      fontWeight: 700,
                      color: keyName === payment ? "white" : "black",
                      fontSize: 24,
                    }}
                  >
                    {keyName}
                  </h4>
                  <img
                    alt={keyName}
                    src={paymentList[keyName].url}
                    style={{ width: "60px", height: "60px" }}
                  />
                </Paper>
              </Grid>
            );
          })}
      </Grid>
    </Paper>
  );
};

export default SelectPayment;
