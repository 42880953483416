import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import DonatePage from "./src/page/Donate";
import ErrorPage from "./src/page/404";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4361ee",
    },
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/:id" element={<DonatePage />} />
          <Route path="/404" element={<ErrorPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

function Home() {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/") window.location.href = "/home.html";
  }, [location.pathname]);

  return <></>;
}
