export const pub = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA1kXzktOdko1a04bOjrzF
Y1dpeWbkPFWy9ysOXUe4E6PvYAclR9o+TEID0mplRXf2bwlhtJbj9WVA1CAnO1nK
x/yfS7hJz8A95oi4NUymPk6YerxdFfiqhDfyJrJp+EFGnCwPNuRk4N/PV8SlldXt
VS2/TUy9eaA0J7wGRW1s0CbgUgLOfNJN6dlBxbqXvLux+5J1VPQlAbaj3xbBC64A
TYWkZZO1CMOGqDBTcClRLNYiGSs5nxUwninh0K2KbWYyS+ZvlcBewukvWIoy1chv
fXqOZuMovw7gNPgn5gV61iW5Lb/r/KQVf9a8DGEVdzPBWnc3XyodprcEJjj7qUQC
s1vVOpjstSz8DxhOU+VQhYI3wFARV09HP8izaFvqLyj2cBN+k7WrlnTZqL0aaAbg
0N41lMB6n6ufQMyz2amGhyoU7CjjorFBqzJbNKVZL59LPgPxXPNEr/wYxjHjUPt+
x9wpWBcUbTlxkJiQF5ujXymRA32IUJWdSZFe86ZHuMXMfGsizPDU33s2VPX+fKJ5
5kI8s5LcxxabJLVQ9REz9HmbrkwtQPk4UwUr+KVeaWWI7sheuSMhiGjLbmtWRZpj
1ftVXMnQ3nej+ugirR+cBGzVeYf9snIgCL+FoA+DuZn5fD7yfvz9DIX4rMc6TORv
L1CPtySAAGpDT3TYkn2uzlECAwEAAQ==
-----END PUBLIC KEY-----`;

export const PRIMARY_COLOR = "#4361ee";
export const LIGHT_GREY = "#888ea8";

export const PRICE = [
  {
    text: "10.000đ",
    value: 10000,
  },
  {
    text: "20.000đ",
    value: 20000,
  },
  {
    text: "50.000đ",
    value: 50000,
  },
  {
    text: "100.000đ",
    value: 100000,
  },
];
