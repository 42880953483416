import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect, useRef } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NinePayModal = ({ open, handleClose, data }) => {
  useEffect(() => {
    loadJS("https://sand-payment.9pay.vn/assets/sdk/js/9pay-sdk-1.0.0.min.js");
  }, []);

  const loadJS = (src) => {
    const ref = window.document.getElementsByTagName("script")[0];
    const script = window.document.createElement("script");
    script.src = src;
    script.async = true;
    ref.parentNode.insertBefore(script, ref);
    script.addEventListener("load", () => {
      window.NinePaySDK.init({
        env: "SANDBOX",
        baseEncode: data.baseEncode,
        signature: data.signature,
      });
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div id="NinePayCheckout"></div>
      </Box>
    </Modal>
  );
};

export default NinePayModal;
