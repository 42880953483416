/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { useEffect, useMemo, forwardRef, useState } from "react";
import styled from "styled-components";
import PaymentInfoModal from "./PaymentInfo_modal";
import {
  postPaymentInfo,
  postEstimate,
  postPaymentNinePay,
} from "../../api/axios";
import { pub, LIGHT_GREY, PRIMARY_COLOR } from "../../helper/const";
import PolicyModal from "../Policy_modal";
import TopDonate from "./TopDonate";
import DonateDisplay from "./DonateDisplay";
import SelectPrice from "./SelectPrice";
import SelectPayment from "./SelectPayment";
import useDebounce from "../../hooks/useDebounce";
import { formatConcurrency } from "../../helper";
import $ from "jquery";
import MobileSidebar from "../MobileSidebar";
// import CreditCardModal from "./CreditModal";
import QRCodeModal from "./QrCodeModal";
import NinePayModal from "./NinePayModel";

const CryptoJS = require("node-crypto-js").Crypt;
export const Title = styled("p")(() => ({
  fontSize: 15,
  fontWeight: 700,
  color: LIGHT_GREY,
  marginBottom: "30px",
}));

export const Center = styled(Box)(() => ({
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
  padding: "20px",
}));

const VIEW_TYPE = {
  TopDonate: "TOP_DONATE",
  Donate: "DONATE",
};

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PaymentEnumType = {
  CREDITCARD: "CREDITCARD",
  ATMCARD: "ATMCARD",
  VIETQR: "VIETQR",
  QRPAY: "QRPAY",
  CREDIT_CARD: "CREDIT_CARD",
  NinePAY: "9PAY",
  ATM_CARD: "ATM_CARD",
};

const DonateTab = ({ topDonate, paymentData }) => {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [price, setPrice] = useState(0);
  const [payment, setPayment] = useState(null);
  const [isOpenSdk, setOpenSdk] = useState({open: false, data: null});

  const [isPrivate, setIsPrivate] = useState(false);
  const [openPaymentInfoModal, setOpenPaymentInfoModal] = useState(false);

  const [openPolicyModal, setOpenPolicyModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fee, setFee] = useState(0);
  const [mobileCurrentView, setMobileCurrentView] = useState(null);
  const [alert, setAlert] = useState({
    type: "success",
    message: "",
    open: false,
  });
  const [qrCodeValue, setQrCodeValue] = useState({ src: "", open: false });
  const isSelectPaymentType = useMemo(() => {
    return price > 0 && name !== "" && message !== "";
  }, [name, price, message]);
  const debouncedPrice = useDebounce(price, 500);

  useEffect(() => {
    getEstimate();
  }, [debouncedPrice, payment]);

  const getEstimate = async () => {
    if (price === 0 || payment === null) return;
    const res = await postEstimate({ amount: price });

    const value =
      payment === PaymentEnumType.QRPAY
        ? { gateway_fee: res.data.data.QR_FREE }
        : res.data.data[payment];
    setFee(value.gateway_fee);
  };

  const onSubmit = async () => {
    if (payment === PaymentEnumType.ATMCARD && !openPaymentInfoModal)
      return setOpenPaymentInfoModal(true);

    onSubmitATMCARD(null);
  };

  const onSubmitATMCARD = async (data) => {
    try {
      setLoading(true);
      const crypt = new CryptoJS();

      const payload = {
        method: payment,
        amount: price + fee,
        user: {
          full_name: name,
        },
        user_id: paymentData.id,
        message_donate: message,
      };
      if (data) payload["pay_data"] = data;
      console.log(payload);

      const encrypted = crypt.encrypt(pub, JSON.stringify(payload));
      let res;
      if (
        payment === PaymentEnumType.CREDIT_CARD ||
        payment === PaymentEnumType.NinePAY ||
        payment === PaymentEnumType.ATM_CARD
      ) {
        res = await postPaymentNinePay({ message: encrypted });
        setOpenSdk({open: true, data: res.data.message});
        return;
      } else {
        res = await postPaymentInfo({ message: encrypted });
      }

      setAlert({
        type: res.data.data.status ? "success" : "error",
        message: res.data.data.message,
        open: true,
      });

      if (!res.data.data.status) return;

      openPaymentResult(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setOpenPaymentInfoModal(false);
    }
  };

  const openPaymentResult = ({ data }) => {
    if (payment === PaymentEnumType.ATMCARD)
      $("#form-ct-napas").html(data.form);
    if (payment === PaymentEnumType.CREDITCARD) {
      window.open(data.checkoutUrl, "_blank");
      // setCreditCardIFrame({ src: res.data.data.checkoutUrl, open: true });
    }

    if (payment === PaymentEnumType.VIETQR || payment === PaymentEnumType.QRPAY)
      setQrCodeValue({ open: true, src: data.qrDataURL });
  };

  return (
    <Box>
      <MobileSidebar
        mobileCurrentView={mobileCurrentView}
        setMobileCurrentView={setMobileCurrentView}
      />
      {mobileCurrentView === VIEW_TYPE.Donate || mobileCurrentView === null ? (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <Paper elevation={3} sx={{ padding: "20px", borderRadius: "6px" }}>
              {paymentData !== null ? (
                <DonateDisplay
                  message={message}
                  price={price}
                  avatar={paymentData.avatar}
                  name={name}
                />
              ) : (
                <Center>
                  <CircularProgress />
                </Center>
              )}
            </Paper>
            <Box sx={{ m: "2rem" }} />
          </Grid>

          <Grid container spacing={{ sm: 2, md: 2, lg: 4 }} rowSpacing={2}>
            <Grid item md={6} xs={12} sm={12} lg={8}>
              <Paper
                elevation={3}
                sx={{ padding: "25px", borderRadius: "6px" }}
              >
                <Title>Thông tin donate</Title>
                <TextField
                  error={false}
                  required
                  label="Họ tên"
                  value={name}
                  helperText=""
                  fullWidth
                  variant="outlined"
                  onChange={(e) => setName(e.target.value)}
                  margin="dense"
                />
                <TextField
                  error={false}
                  required
                  label="Lời nhắn"
                  fullWidth
                  multiline
                  maxRows={4}
                  value={message}
                  helperText=""
                  variant="outlined"
                  onChange={(e) => setMessage(e.target.value)}
                  margin="dense"
                />

                <FormControlLabel
                  sx={{ marginTop: "20px", color: LIGHT_GREY }}
                  onChange={(_, checked) => setIsPrivate(checked)}
                  control={
                    <Checkbox
                      checked={isPrivate}
                      sx={{ color: LIGHT_GREY }}
                      disabled
                    />
                  }
                  label="Nội dung gửi riêng không hiển thị (coming soon)"
                />
              </Paper>
              <Box sx={{ m: "2rem" }} />
              <SelectPrice price={price} setPrice={setPrice} />
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <Paper
                elevation={3}
                sx={{ padding: "20px", borderRadius: "6px" }}
              >
                {paymentData !== null ? (
                  <DonateDisplay
                    message={message}
                    price={price}
                    icon_url={paymentData.icon_url}
                    name={name}
                    messageTemplate={paymentData.message_template}
                  />
                ) : (
                  <Center>
                    <CircularProgress />
                  </Center>
                )}
              </Paper>
              <Box sx={{ m: "2rem" }} />
              <Paper
                elevation={3}
                sx={{
                  minHeight: "400px",
                  padding: "20px",
                  borderRadius: "6px",
                }}
              >
                <TopDonate topDonate={topDonate} />
              </Paper>
            </Grid>
            {isSelectPaymentType && (
              <Grid item xs={12}>
                <SelectPayment payment={payment} setPayment={setPayment} />
              </Grid>
            )}
          </Grid>
          <Box sx={{ m: "2rem" }} />
          {paymentData && payment && (
            <Paper elevation={3} sx={{ padding: "20px" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <div>Chuyển tiền đến {paymentData.name}</div>
                <div>{formatConcurrency(price)}</div>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <div>Phí cổng thanh toán</div>
                <div>{formatConcurrency(fee)}</div>
              </Box>
              <LoadingButton
                loading={loading}
                sx={{ marginTop: "20px" }}
                variant="contained"
                onClick={onSubmit}
                disabled={!(isSelectPaymentType && payment)}
              >
                Submit
              </LoadingButton>
            </Paper>
          )}
          <Grid container sx={{ margin: "20px 0 30px 0" }}>
            <Grid item md={2} xs={0} sm={0}></Grid>
            <Grid item md={8} xs={12} sm={12}>
              <Paper elevation={3} sx={{ padding: "5px 20px" }}>
                © 2022 GWU. Bằng cách tương tác trên trang này, bạn đồng ý với{" "}
                <span
                  style={{ color: PRIMARY_COLOR }}
                  onClick={() => setOpenPolicyModal(true)}
                >
                  Điều khoản và điều kiện
                </span>{" "}
                và của chúng tôi.
              </Paper>
            </Grid>
            <Grid item md={2} xs={0} sm={0}></Grid>
          </Grid>
        </>
      ) : (
        <Paper
          elevation={3}
          sx={{
            minHeight: "400px",
            padding: "20px",
            borderRadius: "6px",
          }}
        >
          <TopDonate topDonate={topDonate} />
        </Paper>
      )}

      {/* form data */}

      <QRCodeModal qrCodeValue={qrCodeValue} setQrCodeValue={setQrCodeValue} />
      <PaymentInfoModal
        open={openPaymentInfoModal}
        handleClose={() => setOpenPaymentInfoModal(false)}
        handleContinue={onSubmitATMCARD}
        loading={loading}
      />
      <PolicyModal
        open={openPolicyModal}
        handleClose={() => setOpenPolicyModal(false)}
      />
      <div id="form-ct-napas"></div>
      {/* <CreditCardModal
        setCreditCardIFrame={(e) => setCreditCardIFrame(e)}
        creditCardIFrame={creditCardIFrame}
      /> */}
      {isOpenSdk.open && <NinePayModal open={true} data={isOpenSdk.data} />}
      <Snackbar
        open={alert.open}
        autoHideDuration={3000}
        onClose={() => setAlert({ ...alert, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DonateTab;
