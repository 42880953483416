import { Modal, TextField, Button, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #d3d3d3",
  borderRadius: "10px",
  p: 4,
};
const PaymentInfoModal = ({ open, handleClose, handleContinue, loading }) => {
  const [cardHolder, setCardHolder] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [issueDate, setIssueDate] = useState("");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, width: { xs: 300, md: 400 } }}>
        <TextField
          autoFocus
          value={cardHolder}
          onChange={(e) => setCardHolder(e.target.value)}
          fullWidth
          variant="outlined"
          label="Họ và tên chủ thẻ"
          margin="dense"
        />
        <Box sx={{ m: "1rem" }} />
        <TextField
          autoFocus
          value={cardNumber}
          onChange={(e) => setCardNumber(e.target.value)}
          fullWidth
          type={"number"}
          variant="outlined"
          label="Số thẻ (bao gồm 16 ký tự)"
          margin="dense"
        />
        <Box sx={{ m: "1rem" }} />
        <TextField
          autoFocus
          value={issueDate}
          onChange={(e) => setIssueDate(e.target.value)}
          fullWidth
          type={"text"}
          variant="outlined"
          label="Ngày hiệu lực (ví dụ: 03/07)"
          margin="dense"
        />
        <Box sx={{ m: "1rem" }} />
        <Divider />
        <Box sx={{ m: "1rem" }} />

        <LoadingButton
          sx={{ width: "100%" }}
          loading={loading}
          onClick={() => handleContinue({ cardHolder, cardNumber, issueDate })}
          variant="contained"
        >
          Tiếp tục
        </LoadingButton>
        <Box sx={{ m: "1rem" }} />
        <Button
          sx={{ width: "100%" }}
          variant="contained"
          color="error"
          onClick={handleClose}
        >
          Huỷ bỏ
        </Button>
      </Box>
    </Modal>
  );
};

export default PaymentInfoModal;
