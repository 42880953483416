import { Box } from "@mui/material";

const ErrorPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: '100vh'
      }}
    >
      <h1>Trang không tồn tại</h1>
      <img src="https://epispost.com/static/media/no-result.903ca85d.svg" alt="error page"/>
    </Box>
  );
};

export default ErrorPage;
