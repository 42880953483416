import { Box } from "@mui/system";
import Header from "./shopping/Header";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import Menu from "./shopping/Menu";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabsMenu = {
  Menu: 0,
  Review: 1,
};

const ShoppingTab = () => {
  const [value, setValue] = useState(TabsMenu.Menu);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ width: { xs: "100%", md: "100%", lg: "70%" }, margin: "0 auto" }}
    >
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <h1>Comming soon</h1>
      <img src="https://epispost.com/static/media/no-result.903ca85d.svg" alt="comming soon"/>
      </Box>
      {/* <Header />
      <Box
        sx={{
          marginTop: "50px",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs menu"
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="Menu" {...a11yProps(0)} />
          <Tab label="Review" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={TabsMenu.Menu}>
        <Menu />
      </TabPanel>
      <TabPanel value={value} index={TabsMenu.Review}>
        Review
      </TabPanel> */}
    </Box>
  );
};

export default ShoppingTab;
