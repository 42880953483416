import { Grid, Paper, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { LIGHT_GREY, PRICE, PRIMARY_COLOR } from "../../helper/const";
import { Title } from "./Donate";

const SelectPrice = ({ price, setPrice }) => {
  const [isTypingPrice, setIsTypingPrice] = useState(false);
  const [activePriceBox, setActivePriceBox] = useState(null);

  const getPriceBox = (index, value) => {
    setActivePriceBox(index);
    setPrice(value);
  };

  const onChangePrice = (e) => {
    setPrice(Number(e.target.value));
    setActivePriceBox(null);
  };

  return (
    <Paper
      elevation={3}
      sx={{ padding: "20px", borderRadius: "6px", marginBottom: "10px" }}
    >
      <Title>Số tiền muốn donate</Title>
      <Box>
        <Grid container spacing={{ sm: 4, md: 1 }} rowSpacing={2}>
          <Grid item lg={3} md={0} xs={0}></Grid>
          <Grid item lg={6} md={12} xs={12} sm={12}>
            <Paper
              onClick={() => setIsTypingPrice(true)}
              elevation={3}
              sx={{
                borderRadius: "10px",
                boxShadow: " 2px 5px 17px 0 rgb(31 45 61 / 17%)",
                padding: "20px",
                cursor: "pointer",
                justifyContent: "center",
                display: "flex",
                height: "30px",
                alignItems: "center",
                backgroundColor:
                  !isTypingPrice && price !== 0 && activePriceBox === null
                    ? PRIMARY_COLOR
                    : "white",
              }}
            >
              {!isTypingPrice ? (
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: 24,
                    color:
                      price !== 0 && activePriceBox === null
                        ? "white"
                        : LIGHT_GREY,
                  }}
                >
                  {price}
                </p>
              ) : (
                <TextField
                  autoFocus
                  value={price}
                  onBlur={() => setIsTypingPrice(false)}
                  onChange={onChangePrice}
                  sx={{
                    input: {
                      textAlign: "center",
                      color: LIGHT_GREY,
                      fontSize: 24,
                    },
                  }}
                  type={"number"}
                  fullWidth
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              )}
            </Paper>
          </Grid>
          <Grid item lg={3} md={0} xs={0}></Grid>
          {PRICE.map(({ value, text }, index) => {
            return (
              <Grid
                key={index}
                item
                md={6}
                xs={12}
                sm={12}
                lg={3}
                onClick={() => getPriceBox(index, value)}
              >
                <Paper
                  elevation={3}
                  sx={{
                    borderRadius: "10px",
                    boxShadow: " 2px 5px 17px 0 rgb(31 45 61 / 17%)",
                    padding: "20px",
                    cursor: "pointer",
                    justifyContent: "center",
                    display: "flex",
                    height: "30px",
                    alignItems: "center",
                    backgroundColor:
                      activePriceBox === index ? PRIMARY_COLOR : "white",
                  }}
                >
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: 24,
                      color: activePriceBox === index ? "white" : LIGHT_GREY,
                    }}
                  >
                    {text}
                  </p>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Paper>
  );
};

export default SelectPrice;
