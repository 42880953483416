/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Paper } from "@mui/material";

import { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import DonateTab from "../components/donate/Donate";
import ShoppingTab from "../components/donate/Shopping";
import { getDonateInfo } from "../api/axios";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PRIMARY_COLOR } from "../helper/const";

const TabsMenu = {
  Donate: 0,
  Shopping: 1,
};

export const Center = styled(Box)(() => ({
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
  padding: "20px",
}));

const DonatePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = useState(TabsMenu.Donate);
  const [paymentData, setPaymentData] = useState(null);
  const [topDonate, setTopDonate] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const slug = location.pathname.split("/")[1];
      const data = await getDonateInfo(slug);

      setPaymentData(data.data.data);
      setTopDonate(data.data.tops);
    } catch (error) {
      console.log("error", error);
      navigate("/404");
    }
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#F5F5F5",
        minHeight: "calc(100vh);",
        position: "relative",
      }}
    >
      <Box
        sx={{
          height: "60px",
          borderBottom: 1,
          borderColor: "divider",
          marginBottom: "20px",
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ position: "absolute", left: 0, top: 0 }}>
          {/* <Paper
            elevation={3}
            sx={{
              padding: "10px",
              borderRadius: "6px",
              width: "400px",
            }}
          > */}
          {paymentData !== null ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={paymentData.avatar}
                alt="avt"
                style={{ width: "50px", height: "50px", marginRight: "10px" }}
              />
              <h3>{paymentData.name}</h3>
            </Box>
          ) : (
            <Center>
              <CircularProgress />
            </Center>
          )}
          {/* </Paper> */}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            onClick={() => handleChange(TabsMenu.Donate)}
            sx={{
              fontWeight: "bold",
              cursor: "pointer",
              marginRight: "20px",
              borderBottom: `2px solid ${
                value === TabsMenu.Donate
                  ? PRIMARY_COLOR
                  : "rgba(0, 0, 0, 0.12)"
              }`,
              height: "60px",
              display: "flex",
              alignItems: "center",
              color: value === TabsMenu.Donate ? PRIMARY_COLOR : "#00000099",
            }}
          >
            Donate
          </Box>
          <Box
            onClick={() => handleChange(TabsMenu.Shopping)}
            sx={{
              fontWeight: "bold",
              cursor: "pointer",
              marginRight: "20px",
              borderBottom: `2px solid ${
                value === TabsMenu.Shopping
                  ? PRIMARY_COLOR
                  : "rgba(0, 0, 0, 0.12)"
              }`,
              height: "60px",
              display: "flex",
              alignItems: "center",
              color: value === TabsMenu.Shopping ? PRIMARY_COLOR : "#00000099",
            }}
          >
            Mua sắm
          </Box>
        </Box>
      </Box>
      {value === TabsMenu.Donate && (
        <Box value={value}>
          <DonateTab topDonate={topDonate} paymentData={paymentData} />
        </Box>
      )}
      {value === TabsMenu.Shopping && (
        <Box value={value}>
          <ShoppingTab />
        </Box>
      )}
    </Box>
  );
};

export default DonatePage;
