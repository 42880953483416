import { Box } from "@mui/system";
import styled from "styled-components";
// import { formatConcurrency } from "../../helper";
import { PRIMARY_COLOR } from "../../helper/const";

const Th = styled("th")(() => ({
  textAlign: "left",
  color: PRIMARY_COLOR,
}));

const TopDonate = ({ topDonate }) => {
  return (
    <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
      <h2>Comming soon</h2>
      <img
        src="https://epispost.com/static/media/no-result.903ca85d.svg"
        alt="comming soon"
        style={{ width: "300px", height: "300px" }}
      />
    </Box>
    // <table style={{ width: "100%" }}>
    //   <thead>
    //     <tr>
    //       <Th>#</Th>
    //       <Th>Họ tên</Th>
    //       <Th>Số tiền</Th>
    //     </tr>
    //   </thead>
    //   <Box sx={{ m: "1rem" }} />
    //   <tbody>
    //     {topDonate.length !== 0 &&
    //       topDonate.map(({ amount, name }, index) => {
    //         return (
    //           <tr key={index}>
    //             <td>{index + 1}</td>
    //             <td>{name}</td>
    //             <td>{formatConcurrency(amount)}</td>
    //           </tr>
    //         );
    //       })}
    //   </tbody>
    // </table>
  );
};

export default TopDonate;
