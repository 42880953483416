import { Box } from "@mui/material";
import { PRIMARY_COLOR } from "../../helper/const";

const DonateDisplay = ({ icon_url, name, price, message, messageTemplate }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ width: "120px" }}>
        <img
          src={icon_url}
          alt={name}
          style={{ width: "100px", height: "100px" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box>
          <span>{name === "" ? "{name} " : `${name} `} </span>
          <span>{messageTemplate ? messageTemplate : 'Đã gửi bạn'} </span>
          <span>{price === 0 ? "{price} " : `${price} `} </span>
        </Box>
        <span
          style={{
            color: PRIMARY_COLOR,
            justifyContent: "center",
            display: "flex",
            paddingTop: "5px",
          }}
        >
          {message === "" ? "{message} " : message}
        </span>
      </Box>
    </Box>
  );
};

export default DonateDisplay;
