import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { QRCodeCanvas } from "qrcode.react";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #d3d3d3",
  borderRadius: "10px",
  p: 4,
};
const QRCodeModal = ({ qrCodeValue, setQrCodeValue }) => {
  return (
    <Modal
      open={qrCodeValue.open}
      onClose={() => setQrCodeValue({ open: false })}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, width: { xs: 300, md: 300 }, height: 300 }}>
        <QRCodeCanvas value={qrCodeValue.src} style={{with: '100%',height: '100%'}} size={300} />
      </Box>
    </Modal>
  );
};

export default QRCodeModal;
